import React from "react";
import { GridCheckIcon, GridCloseIcon, GridRenderCellParams } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import DataGridHelper from "./DataGridHelper";
import DateTime from "../../../core/helpers/date/DateTime";
import { DATE_FORMAT, DATETIME_FORMAT } from "../../../commons/constants/env";

import Chip from "@mui/material/Chip";
import { ArrowCircleUpOutlined, ArrowCircleDownOutlined, CheckCircleOutlineOutlined } from '@mui/icons-material';
import { isArray } from "lodash";
import { useTranslation } from "react-i18next";

interface GridCellProps {
    type?: string;
    options?: any;
    value?: any;
    params: GridRenderCellParams | any;
}

const GridCell = React.memo(function GridCell(
    props: GridCellProps,
) {
    const theme = createTheme();
    const { options, params } = props;
    const { t } = useTranslation();

    const renderElement = () => {
        let data = params?.value?.value ?? params?.value;
        const valueType = DataGridHelper.retype(data?.type || props?.type);

        if (params.colDef.valueFormatter && data instanceof Object) {
            data = params.colDef.valueFormatter(params);
        }

        let valueOptions = options
        if (params.colDef.valueOptions && data instanceof Object) {
            valueOptions = params.colDef.valueOptions(params);
        }

        if (!valueOptions) {
            valueOptions = options;
        }

        switch (valueType) {
            case 'boolean':
            case 'checkbox': {
                return (data)
                    ? <GridCheckIcon style={{color: theme.palette.success.light,}} />
                    : <GridCloseIcon style={{color: grey[500]}} />;
            }
            case 'date': {
                return DateTime.toString(data, DATE_FORMAT);
            }
            case 'dateTime':
            case 'datetime': {
                return DateTime.toString(data, DATETIME_FORMAT);
            }
            case 'data':{
                return (data) ? JSON.stringify(data, null, 2) : data;
            }
            case 'choice':
            case 'select':
            case 'singleSelect':
            case 'radio': {
                const option = valueOptions.find(
                    (p: any) => String(p.value) === String(data)
                        || String(p.value) === String(params.value?.value)
                )

                return option?.label || String(data);
            }
            case 'range_indicator': {
                switch (isArray(data) ? data[0] : data) {
                    case -1: {
                        return <Chip label={t('low')} variant="outlined" color="error" icon={<ArrowCircleDownOutlined />} />
                    }
                    case 0: {
                        return <Chip label={t('norm')} variant="outlined" color="success" icon={<CheckCircleOutlineOutlined />} />;
                    }
                    case 1: {
                        return <Chip label={t('high')} variant="outlined" color="error" icon={<ArrowCircleUpOutlined />} />;
                    }
                    default:
                        return null;
                }
            }
            default: {
                return data;
            }
        }
    }

    return renderElement();
});

export default GridCell