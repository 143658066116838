import DateTime from "../../../core/helpers/date/DateTime";
import { DATE_FORMAT, DATETIME_FORMAT, TIME_FORMAT } from "../../../commons/constants/env";
import { isDate } from "date-fns";

export default class DataGridHelper
{
    public static formatValue(type: string = 'string', value: any): any
    {
        switch (type) {
            case 'checkbox': {
                return value ? 1 : 0;
            }
            case 'birth_date':
            case 'date': {
                return DateTime.formatValue(type, value);
            }
            case 'dateTime':
            case 'datetime':
            case 'datetime-local': {
                return DateTime.formatValue(type, value);
            }
            case 'time': {
                return DateTime.formatValue(type, value);
            }
            default: {
                return value ?? ''
            }
        }
    }

    public static retype(type: string): string
    {
        switch (type) {
            case 'boolean':
            case 'checkbox': {
                return 'boolean';
            }
            case 'date':
            case 'birth_date': {
                return 'date';
            }
            case 'dateTime':
            case 'datetime':
            case 'datetime-local':
            case 'time': {
                return 'dateTime';
            }
            case 'integer':
            case 'number':
            case 'range': {
                return 'number'
            }
            case 'range_indicator': {
                return 'range_indicator';
            }
            case 'choice':
            case 'select':
            case 'singleSelect': {
                return 'singleSelect';
            }
            case 'radio': {
                return 'radio';
            }
            case 'data':
            case 'field': {
                return 'data';
            }
            default: {
                return 'string';
            }
        }
    }

    public static valueGetter(value: any): any {
        let _g: any = ''

        if (typeof value === 'string' || isDate(value)) {
            return value
        }

        if (value?.value) {
            _g = value?.value

            if (isDate(_g)) {
                return _g
            }
        }

        if (_g?.value) {
            _g = Array.isArray(_g?.value) ? _g?.value[0] : _g?.value
        }

        if (_g?.value && typeof _g?.value !== 'string') {
            _g = this.valueGetter(_g)
        }

        return _g || _g?.value || ''
    }
}