import { createStyles, makeStyles } from "@mui/styles";
import { createTheme, styled } from "@mui/material/styles";
import React from "react";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Paper,
    Popper,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {isArray, map} from "lodash";
import {GridCheckIcon, GridCloseIcon, GridRenderCellParams, GridRenderEditCellParams} from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import DataGridHelper from "./DataGridHelper";

interface GridCellExpandProps {
    type?: string;
    options: any;
    value?: any;
    width?: number;
    params: GridRenderCellParams | any;
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            alignItems: 'center',
            lineHeight: '24px',
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            '& .cellValue': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
    }),
);

function isOverflown(element: Element): boolean {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}

const GridCellExpand = React.memo(function GridCellExpand(
    props: GridCellExpandProps,
) {
    const theme = createTheme();
    const classes = useStyles();

    const { width, type, options, params } = props;
    const wrapper = React.useRef<HTMLDivElement | null>(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);
    const { id, value, api, field, colDef } = params;

    const handleMouseEnter = () => {
        // const isCurrentlyOverflown = isOverflown(cellValue.current!);
        const isCurrentlyOverflown = true;
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv?.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent: KeyboardEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    const renderShortElement = (readOnly: boolean = true) => {
        let data = params?.value?.value ?? params?.value;
        const valueType = DataGridHelper.retype(data?.type || props?.type);

        if (colDef.valueFormatter && data instanceof Object) {
            data = params.colDef.valueFormatter(params);
        }

        switch (valueType) {
            case 'checkboxes':
            case 'checkbox_multiple': {
                const value = Array.isArray(params.value) ? params.value[0] : params.value
                return map(options?.choices, (val, key) => {
                    return (Array.isArray(value) && value.includes(val))
                        ? <GridCheckIcon style={{color: theme.palette.success.light,}} />
                        : <GridCloseIcon style={{color: grey[500]}} />;
                });
            }
            case 'radio': {
                const value = Array.isArray(params.value) ? params.value[0] : params.value
                return map(options?.choices, (val, key) => {
                    return (String(value) === String(val))
                        ? <GridCheckIcon style={{color: theme.palette.success.light}} />
                        : <GridCloseIcon style={{color: grey[500]}} />;
                });
            }
            case 'data':
            case 'field':{
                let items: any = [];
                if (typeof data === 'object') {
                    Object.keys(data).forEach(function (key, v, a) {
                        if (Array.isArray(data[key]) || typeof data[key] === 'object') {
                            items.push(key + ': ' + data[key].join('; '));
                        }
                    });
                } else {
                    items.push(data)
                }

                return (data) ? items.join(', ') : data;
            }
            default:
                return value
        }
    }

    const renderElement = (readOnly: boolean = true) => {
        let data = params?.value?.value ?? params?.value;
        const valueType = DataGridHelper.retype(data?.type || props?.type);

        if (colDef.valueFormatter && data instanceof Object) {
            data = params.colDef.valueFormatter(params);
        }

        switch (valueType) {
            case 'checkboxes':
            case 'checkbox_multiple': {
                return (
                    <FormGroup>
                        {map(options?.choices, (val, key) => {
                            return (
                                <FormControlLabel
                                    value={val}
                                    control={<Checkbox defaultChecked={Array.isArray(params.value) && params.value.includes(val)} />}
                                    label={key}
                                    disabled={true}
                                />)
                        })}
                    </FormGroup>
                );
            }
            case 'radio': {
                const value = Array.isArray(params.value) ? params.value[0] : params.value
                return (
                    <FormControl>
                        <RadioGroup value={String(value)}>
                            {map(options?.choices, (val, key) => {
                                return (<FormControlLabel value={String(val)} control={<Radio key={key} />} label={key} disabled={true} />)
                            })}
                        </RadioGroup>
                    </FormControl>
                );
            }
            case 'data':
            case 'field': {
                let items: any = [];
                if (typeof data === 'object') {
                    Object.keys(data).forEach(function (key, v, a) {
                        if (Array.isArray(data[key]) || typeof data[key] === 'object') {
                            items.push(key + ': ' + data[key].join('; '));
                        }
                    });
                } else {
                    items.push(data)
                }

                const list = items.map((item: any) => {
                    return <li>{item}</li>;
                });

                return (
                    <Box pl={1} pr={1}>
                        <Box component="ul" aria-labelledby="category-b" sx={{ pl: 3, pr: 3 }}>
                            {list}
                        </Box>
                    </Box>
                );
            }
            default:
                return value
        }
    }

    return (
        <div
            ref={wrapper}
            className={classes.root}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                ref={cellDiv}
                style={{
                    height: 1,
                    width: width || colDef.computedWidth,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <div ref={cellValue} className="cellValue">
                {renderShortElement()}
            </div>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                >
                    <Paper
                        elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}
                    >
                        <Typography component="span" variant="body2" style={{ padding: 8 }}>
                            {renderElement()}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </div>
    );
});

export default GridCellExpand